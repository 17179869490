import loadable from "@loadable/component";
import clsx from "clsx";

const Connector = loadable(() => import("@micado-digital/react-form/Connector"));
const Container = loadable(() => import("@Components/views/Container"));
const Form = loadable(() => import("@micado-digital/react-form"));
const ReactCopyrights01 = loadable(() =>
	import("@micado-digital/react-copyrights/ReactCopyrights01")
);
const ReactDropDown01 = loadable(() =>
	import("@micado-digital/react-dropdown/ReactDropDown01")
);
const ReactFile01 = loadable(() => import("@micado-digital/react-file/ReactFile01"));
const ReactGallery01 = loadable(() => import("@micado-digital/react-gallery/ReactGallery01"));
const ReactHeadline01 = loadable(() =>
	import("@micado-digital/react-headline/ReactHeadline01")
);
const ReactHTML = loadable(() => import("@micado-digital/react-html/ReactHTML"));
const ReactImageText03 = loadable(() =>
	import("@micado-digital/react-imagetext/ReactImageText03")
);
const ReactList01 = loadable(() => import("@micado-digital/react-list/ReactList01"));
const ReactReferences01 = loadable(() =>
	import("@micado-digital/react-references/ReactReferences01")
);
const ReactSingleImage04 = loadable(() =>
	import("@micado-digital/react-singleimage/ReactSingleImage04")
);
const ReactSingleTeaser02 = loadable(() =>
	import("@micado-digital/react-singleteaser/ReactSingleTeaser02")
);
const ReactSingleTeaser03 = loadable(() =>
	import("@micado-digital/react-singleteaser/ReactSingleTeaser03")
);
const ReactSVGIcon = loadable(() => import("@micado-digital/react-svg-icon/ReactSVGIcon"));
const ReactTable01 = loadable(() => import("@micado-digital/react-table/ReactTable01"));
const ReactTeaserList02 = loadable(() =>
	import("@micado-digital/react-teaser-list/ReactTeaserList02")
);
const ReactText01 = loadable(() => import("@micado-digital/react-text/ReactText01"));
const ReactVideo02 = loadable(() => import("@micado-digital/react-video/ReactVideo02"));
const ReactLegacy = loadable(() => import("@micado-digital/react-legacy/ReactLegacy"));

const getJSXElement = (element, lang) => {
	const { REACT_APP_PATH } = process.env;
	const { id, tag } = element;

	switch (tag) {
		case "basic-headline": {
			const { text, variant = 0 } = element;

			let config = {};

			switch (variant) {
				case 0: {
					config = {
						component: "h1",
						variants: {
							xs: "display-medium",
							md: "display-large"
						}
					};
					break;
				}
				case 1: {
					config = {
						component: "h2",
						variant: "display-medium"
					};
					break;
				}
				case 2: {
					config = {
						component: "h3",
						variant: "display-small"
					};
					break;
				}
				case 3: {
					config = {
						component: "h4",
						variant: "headline-large"
					};
					break;
				}
				case 4: {
					config = {
						component: "h5",
						variant: "headline-medium"
					};
					break;
				}
				case 5: {
					config = {
						component: "h6",
						variant: "headline-small"
					};
					break;
				}
				case 6: {
					config = {
						component: "p",
						variant: "title-small"
					};
					break;
				}
				default: {
					break;
				}
			}

			return (
				<Container key={id} tag={tag}>
					<ReactHeadline01
						component={config?.component}
						text={text}
						variant={config?.variant}
						variants={config?.variants}
					/>
				</Container>
			);
		}

		case "basic-text": {
			const { text, variant } = element;

			return (
				<Container
					className={clsx({
						"mco-view-component-container-basic-text-variant-1": variant === 1
					})}
					key={id}
					tag={tag}
				>
					<ReactText01 text={text} variant="body-medium" />
				</Container>
			);
		}

		case "basic-list": {
			const { textcontent, title, variant } = element;

			return (
				<Container
					className={clsx({
						"mco-view-component-container-basic-list-variant-1": variant === 1
					})}
					key={id}
					tag={tag}
				>
					<ReactList01
						headlineVariant="headline-large"
						icon={<ReactSVGIcon color="primary" src="/img/icons/check.svg" size={24} />}
						textcontent={textcontent}
						textVariant="body-medium"
						title={title}
						variant={variant}
					/>
				</Container>
			);
		}

		case "basic-table": {
			const { addition, title, textcontent } = element;

			const newTableArray = [];

			for (const entries of textcontent?.items) {
				const rows = entries?.items;
				const columns = rows.map(column => {
					const columnObj = {
						text: column?.text,
						align: column?.["attr-align"],
						width: column?.["attr-width"]
					};
					return columnObj;
				});

				newTableArray.push(columns);
			}

			return (
				<Container key={id} tag={tag}>
					<ReactTable01
						addition={addition}
						headlineVariant="headline-large"
						items={newTableArray}
						title={title}
					/>
				</Container>
			);
		}

		case "basic-links": {
			const { elements, title } = element;

			return (
				<Container key={id} tag={tag}>
					<ReactReferences01
						elements={elements}
						hasIcon={false}
						headlineVariant="headline-large"
						linkVariant="body-medium"
						startIcon={<ReactSVGIcon src="/img/icons/chevron-right.svg" size={24} />}
						title={title}
					/>
				</Container>
			);
		}

		case "basic-video": {
			const { media, title, text } = element;

			return (
				<Container key={id} tag={tag}>
					<ReactVideo02
						additionVariant="body-small"
						headlineVariant="headline-large"
						media={media[0]}
						mediaFormats={{
							xs: "video-mobile",
							sm: "video"
						}}
						mediaPath={REACT_APP_PATH}
						title={title}
						text={text}
					/>
				</Container>
			);
		}

		case "basic-html": {
			const { text } = element;

			return (
				<Container key={id} tag={tag}>
					<ReactHTML key={id} html={text} />
				</Container>
			);
		}

		case "basic-download-group": {
			const { children } = element;

			return (
				<Container key={id} tag={tag}>
					{children?.map(item => getJSXElement(item))}
				</Container>
			);
		}

		case "basic-download": {
			const { media, title } = element;

			return (
				<Container key={id} tag={tag}>
					<ReactFile01 media={media} title={title} />
				</Container>
			);
		}

		case "basic-copyrights": {
			return (
				<Container key={id} tag={tag}>
					<ReactCopyrights01 />
				</Container>
			);
		}

		case "basic-imprint": {
			return (
				<Container key={id} tag={tag}>
					<ReactLegacy lang={lang} logoIdentifier="logo" />
				</Container>
			);
		}

		case "basic-dropdown-group": {
			const { children } = element;

			return (
				<Container key={id} tag={tag}>
					<ReactDropDown01 option={0} title={element?.children?.[0]?.title}>
						<>
							{children
								?.filter(item => item.tag !== "basic-dropdown")
								?.map(item => getJSXElement(item))}
						</>
					</ReactDropDown01>
				</Container>
			);
		}

		case "basic-singleimage": {
			const { addition, media, option2, reference, title, text } = element;

			return (
				<Container key={id} maxWidth={2560} noPadding tag={tag}>
					<ReactSingleImage04
						addition={addition}
						media={media ? media?.[0] : {}}
						mediaFormats={{
							xs: "singleimage-mobile",
							sm: "singleimage"
						}}
						mediaPath={REACT_APP_PATH}
						option2={option2}
						reference={reference}
						text={text}
						title={title}
						titleVariant="display-medium"
					/>
				</Container>
			);
		}

		case "basic-teaser": {
			const { id, teasergroup: { items = [] } = {}, title } = element;

			return (
				<Container key={id} tag={tag}>
					<ReactTeaserList02 headline={title} headlineVariant="headline-large">
						{items?.map((item, index) => {
							const variant = index % 2;

							return (
								<ReactSingleTeaser03
									buttonColor="primary"
									headlineVariants={{
										xs: "headline-large",
										md: "display-medium"
									}}
									key={index}
									media={item?.media?.[0]}
									mediaPath={REACT_APP_PATH}
									mediaFormats={{
										xs: "teaser-mobile",
										md: "teaser"
									}}
									teaser={item}
									textVariant="body-medium"
									variant={variant}
								/>
							);
						})}
					</ReactTeaserList02>
				</Container>
			);
		}

		case "basic-singleteaser": {
			const { id, teaser, variant } = element;

			if (variant === 2 || variant === 3)
				return (
					<Container key={id} noPadding tag={tag}>
						<ReactSingleTeaser02
							buttonColor="primary"
							headlineVariants={{
								xs: "headline-large",
								md: "display-medium"
							}}
							teaser={teaser}
							media={teaser?.media?.[0]}
							mediaPath={REACT_APP_PATH}
							mediaFormats={{
								xs: "teaser-mobile",
								md: "teaser"
							}}
							textVariant="body-medium"
							variant={variant}
						/>
					</Container>
				);

			return (
				<Container key={id} tag={tag}>
					<ReactSingleTeaser03
						buttonColor="primary"
						headlineVariants={{
							xs: "headline-large",
							md: "display-medium"
						}}
						teaser={teaser}
						media={teaser?.media?.[0]}
						mediaPath={REACT_APP_PATH}
						mediaFormats={{
							xs: "teaser-mobile",
							md: "teaser"
						}}
						textVariant="body-medium"
						variant={variant}
					/>
				</Container>
			);
		}

		case "basic-imagetext": {
			const { addition2, media, option, text, title } = element;

			return (
				<Container key={id} noPadding tag={tag}>
					<ReactImageText03
						addition={title}
						additionVariant="body-small"
						headlineVariants={{
							xs: "headline-large",
							md: "display-medium"
						}}
						media={media?.[0]}
						mediaFormats={{
							xs: "imagetext-combi-mobile",
							md: "imagetext-combi"
						}}
						mediaPath={REACT_APP_PATH}
						title={addition2}
						text={text}
						textVariant="body-medium"
						variant={option}
					/>
				</Container>
			);
		}

		case "basic-gallery": {
			const { elements } = element;

			return (
				<Container key={id} tag={tag}>
					<ReactGallery01
						elements={elements}
						mediaFormats={{
							xs: "gallery-preview"
						}}
						overlayMediaFormats={{
							xs: "gallery"
						}}
					/>
				</Container>
			);
		}

		case "basic-form": {
			const { elements } = element;

			return (
				<Container key={id} tag={tag}>
					<Form action={`${REACT_APP_PATH}/submitform.json.api`} method="POST">
						<Connector formElements={elements} formID={id} />
					</Form>
				</Container>
			);
		}

		default:
			if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
				console.warn("missing element:", element);
			}
			return null;
	}
};

export default getJSXElement;
