/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import clsx from "clsx";
import loadable from "@loadable/component";
import PageContext from "@PageContext";
import WebProfile from "@micado-digital/react-ui/components/WebProfile";
import AccountData from "@micado-digital/react-ui/components/AccountData";
import SkiShopData from "@micado-digital/react-ski-shop/components/SkiShopData";
import UiContext from "@UiContext";
import PageTransition from "@micado-digital/react-ui/components/PageTransition";
import useIsCheckout from "@micado-digital/react-ski-shop/hooks/useIsCheckout";
import Locals from "@micado-digital/react-ui/components/Locals";
import useIsCurrentLocation from "@micado-digital/react-ui/hooks/useIsCurrentLocation";
import useTheme from "@material-ui/core/styles/useTheme";
import Header from "@Components/views/Header";
import usePartnerSalesChannel from "../hooks/usePartnerSalesChannel";
import Default from "./Default";
import Core from "./Core";

import styles from "./Views.styles";

const SkiShop = loadable(() => import("./SkiShop"));
const Footer = loadable(() => import("@Components/views/Footer"));
const DachsteinPlusClub = loadable(() => import("./SkiShop/DachsteinPlusClub"));
const DachsteinPlusClubRightColumn = loadable(() =>
	import("./SkiShop/DachsteinPlusClub/RightColumn")
);

const Views = () => {
	const { REACT_APP_PATH } = process.env;
	const [pageData] = useContext(PageContext);
	const { authorID, fields, id, lang, layoutTag } = pageData;
	const [ui, setUi] = useContext(UiContext);
	const { mobileMenuOpen } = ui;
	const css = styles();
	const theme = useTheme();
	const currentLocation = useIsCurrentLocation();
	const skiShopURLPath = "ski-shop";
	const skiShopIsCheckout = useIsCheckout({ path: skiShopURLPath });
	const {
		palette: {
			primary: { light: primaryMainColor }
		}
	} = theme;

	const { isPartnerMode } = usePartnerSalesChannel();

	useEffect(() => {
		if (mobileMenuOpen) {
			setUi(prevState => ({ ...prevState, mobileMenuOpen: false }));
		}
	}, [document.URL]);

	const getSeason = () => {
		if (fields && fields?.length > 0) {
			const season = fields?.filter(item => item?.tag === "season");

			if (season?.length > 0) {
				return season
					?.map(item => item?.value?.value)
					.join()
					.toLowerCase();
			} else {
				return "winter";
			}
		} else {
			return "winter";
		}
	};

	if (!lang) return null;

	const hasFooter = currentLocation && !skiShopIsCheckout;

	let overwritingRoutes = {};

	if (isPartnerMode) {
		overwritingRoutes = {
			list: lang === "de" ? `/de/vorteils-code.html` : "/en/benefit-code.html"
		};
	}

	return (
		<WebProfile>
			<AccountData authorID={authorID}>
				<Locals url={`/locals/${lang || "de"}.json`}>
					<SkiShopData
						additionalDashboardTiles={[
							{
								componentLeft: <DachsteinPlusClub lang={lang} />,
								componentRight: <DachsteinPlusClubRightColumn lang={lang} />,
								icon: "/img/icons/skishop/dashboard-plus-club.svg",
								identifier: "plusclub",
								link: "/:lang/ski-shop/dashboard/dachstein-plus-club",
								title: "Dachstein plus – Club"
							}
						]}
						creditcards={["mastercard", "visa", "dinersclub", "discover"]}
						lang={lang}
						oAuth
						overwritingRoutes={overwritingRoutes}
						urlPath={skiShopURLPath}
					>
						<div
							className={clsx(css.page, `mco-page season-${getSeason()}`, {
								[css.pageSkiShop]: layoutTag === "ski-shop",
								"mco-page-ski-shop": layoutTag === "ski-shop",
								[css.pageSkiShopCheckout]: skiShopIsCheckout,
								"mco-page-ski-shop-checkout": skiShopIsCheckout
							})}
						>
							<Header lang={lang} season={getSeason()} />

							{/* avoid PageTransition in ski-shop - because of scroll problems */}

							{layoutTag !== "ski-shop" && (
								<PageTransition color={primaryMainColor} position="top" />
							)}
							{layoutTag === "default" && currentLocation && <Default lang={lang} />}
							{layoutTag === "ski-shop" && currentLocation && <SkiShop lang={lang} />}
							{hasFooter && <Footer lang={lang} season={getSeason()} />}
							<micado-ial language={lang} path={REACT_APP_PATH} page-id={id}></micado-ial>
						</div>
						<Core />
					</SkiShopData>
				</Locals>
			</AccountData>
		</WebProfile>
	);
};

export default Views;
