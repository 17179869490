import React, { useEffect, useState } from "react";
import clsx from "clsx";
import loadable from "@loadable/component";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/styles/useTheme";

import styles from "./Header.styles";
import Desktopmenu from "./Desktopmenu";

const Container = loadable(() => import("@Components/views/Container"));
const Logo = loadable(() => import("@Components/views/Logo"));
const Icons = loadable(() => import("./Icons"));

const Header = ({ lang, season }) => {
	const theme = useTheme();
	const css = styles({ season });
	const [scrollPosition, setScrollPosition] = useState(0);
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const handleScroll = () => {
		const position = window.pageYOffset;
		setScrollPosition(position);
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll, { passive: true });

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<div
			className={clsx(css.header, "mco-view-component-header", {
				[css.headerScroll]: scrollPosition > 0,
				"mco-view-component-header--scroll": scrollPosition > 0
			})}
		>
			<Container className={clsx(css.container, "mco-view-component-header__container")}>
				<Logo lang={lang} />
				{!isMobile && <Desktopmenu />}
				<Icons lang={lang} />
			</Container>
			{isMobile && <Desktopmenu />}
		</div>
	);
};

export default Header;
