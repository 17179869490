export const defaultTheme = {
	palette: {
		primary: {
			light: "#5e9bc2",
			main: "#0063a4",
			dark: "#0063a4"
		},
		secondary: {
			light: "#5e9bc2",
			main: "#0063a4",
			dark: "#0063a4"
		},
		error: {
			light: "#f08080",
			main: "#d50b0b",
			dark: "#8b0101"
		},
		warning: {
			light: "#ffde77",
			main: "#ffc105",
			dark: "#d19e05"
		},
		info: {
			light: "#77b6da",
			main: "#0175b9",
			dark: "#01446c"
		},
		success: {
			light: "#a4bf7a",
			main: "#70992e",
			dark: "#477301"
		},
		grey: {
			50: "#f7f7f8",
			100: "#e5e5e6",
			200: "#d0d1d2",
			300: "#b1b2b4",
			400: "#97999b",
			500: "#7d7f82",
			600: "#646668",
			700: "#4b4c4e",
			800: "#323334",
			900: "#19191a",
			A100: "#d0d1d2",
			A200: "#b1b2b4",
			A400: "#323334",
			A700: "#646668"
		},
		text: {
			primary: "#0063a4",
			secondary: "#666666"
		},
		background: {
			default: "#FFFFFF",
			header: "#ffffff",
			teaser: "#FFFFFF",
			light: "#D1EDFF",
			dark: "#004572",
			winter: "#D1EDFF",
			summer: "#E7EFCA"
		}
	},
	typography: {
		htmlFontSize: 16,
		fontFamily: "CadizWeb",
		fontSize: 16,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 700,
		fontWeightBold: 700,
		"display-large": {
			fontFamily: "CadizWeb",
			fontWeight: 600,
			fontSize: 65,
			letterSpacing: 0,
			lineHeight: 1.2
		},
		"display-medium": {
			fontFamily: "CadizWeb",
			fontWeight: 600,
			fontSize: 48,
			letterSpacing: 0,
			textTransform: "uppercase",
			lineHeight: "53px"
		},
		"display-small": {
			fontFamily: "CadizWeb",
			fontWeight: 600,
			fontSize: 42,
			letterSpacing: 0,
			textTransform: "uppercase",
			lineHeight: "47px"
		},
		"headline-large": {
			fontFamily: "CadizWeb",
			fontWeight: 600,
			fontSize: 38,
			letterSpacing: 0,
			lineHeight: "42px"
		},
		"headline-medium": {
			fontFamily: "CadizWeb",
			fontWeight: 600,
			fontSize: 32,
			letterSpacing: 0,
			lineHeight: 1.4
		},
		"headline-small": {
			fontFamily: "CadizWeb",
			fontWeight: 400,
			fontSize: 30,
			letterSpacing: 0,
			lineHeight: "33px"
		},
		"title-large": {
			fontFamily: "CadizWeb",
			fontWeight: 600,
			fontSize: 28,
			letterSpacing: 0,
			lineHeight: "37px"
		},
		"title-medium": {
			fontFamily: "CadizWeb",
			fontWeight: 600,
			fontSize: 24,
			letterSpacing: 0,
			lineHeight: "32px"
		},
		"title-small": {
			fontFamily: "CadizWeb",
			fontWeight: 600,
			fontSize: 16,
			letterSpacing: 2.6999999999999997,
			textTransform: "uppercase",
			lineHeight: "24px"
		},
		"body-large": {
			fontFamily: "CadizWeb",
			fontWeight: 400,
			fontSize: 20,
			letterSpacing: 0.2,
			lineHeight: "30px"
		},
		"body-medium": {
			fontFamily: "CadizWeb",
			fontWeight: 400,
			fontSize: 18,
			letterSpacing: 0.18,
			lineHeight: "27px"
		},
		"body-small": {
			fontFamily: "CadizWeb",
			fontWeight: 400,
			fontSize: 16,
			letterSpacing: 0.8,
			lineHeight: "24px"
		},
		"label-large": {
			fontFamily: "CadizWeb",
			fontWeight: 600,
			fontSize: 20,
			letterSpacing: 0,
			textTransform: "uppercase",
			lineHeight: "26px"
		},
		"label-medium": {
			fontFamily: "CadizWeb",
			fontWeight: 600,
			fontSize: 16,
			letterSpacing: 1,
			textTransform: "uppercase",
			lineHeight: "21px"
		},
		"label-small": {
			fontFamily: "CadizWeb",
			fontWeight: 600,
			fontSize: 14,
			letterSpacing: 0.7000000000000001,
			lineHeight: "19px"
		},
		h1: {
			fontFamily: "CadizWeb",
			fontWeight: 400,
			fontSize: 56,
			letterSpacing: 0,
			textTransform: "uppercase",
			lineHeight: "62px"
		},
		h2: {
			fontFamily: "CadizWeb",
			fontWeight: 400,
			fontSize: 48,
			letterSpacing: 0,
			textTransform: "uppercase",
			lineHeight: "53px"
		},
		h3: {
			fontFamily: "CadizWeb",
			fontWeight: 400,
			fontSize: 42,
			letterSpacing: 0,
			textTransform: "uppercase",
			lineHeight: "47px"
		},
		h4: {
			fontFamily: "CadizWeb",
			fontWeight: 400,
			fontSize: 38,
			letterSpacing: 0,
			lineHeight: "42px"
		},
		h5: {
			fontFamily: "CadizWeb",
			fontWeight: 400,
			fontSize: 32,
			letterSpacing: 0,
			lineHeight: "36px"
		},
		h6: {
			fontFamily: "CadizWeb",
			fontWeight: 400,
			fontSize: 30,
			letterSpacing: 0,
			lineHeight: "33px"
		},
		subtitle1: {
			fontFamily: "CadizWeb",
			fontWeight: 400,
			fontSize: 20,
			letterSpacing: 0.2,
			lineHeight: "30px"
		},
		subtitle2: {
			fontFamily: "CadizWeb",
			fontWeight: 400,
			fontSize: 18,
			letterSpacing: 0.18,
			lineHeight: "27px"
		},
		overline: {
			fontFamily: "CadizWeb",
			fontWeight: 400,
			fontSize: 16,
			letterSpacing: 0.8,
			lineHeight: "24px"
		},
		"button-large": {
			fontFamily: "CadizWeb",
			fontWeight: 600,
			fontSize: 20,
			letterSpacing: 0,
			textTransform: "uppercase",
			lineHeight: "26px"
		},
		"button-medium": {
			fontFamily: "CadizWeb",
			fontWeight: 600,
			fontSize: 16,
			letterSpacing: 4,
			textTransform: "uppercase",
			lineHeight: "21px"
		},
		"button-small": {
			fontFamily: "CadizWeb",
			fontWeight: 600,
			fontSize: 14,
			letterSpacing: 0.7000000000000001,
			lineHeight: "19px"
		}
	},
	shape: {
		borderRadius: 6
	},
	overrides: {
		MuiButton: {
			outlinedPrimary: {
				borderColor: "#0063a4 !important",
				color: "#0063a4 !important",
				"&:hover": {
					backgroundColor: "rgba(230, 0, 126, 0.04) !important"
				}
			},
			containedPrimary: {
				background: "#0063a4 !important",
				"&:hover": {
					backgroundColor: "#0063a4 !important"
				}
			},
			root: {
				borderRadius: "6px !important",
				boxShadow: "none !important",
				fontWeight: 600,
				minHeight: 48,
				textTransform: "uppercase"
			},
			sizeLarge: {
				fontSize: 18,
				minHeight: 52
			},
			sizeSmall: {
				fontSize: 14,
				minHeight: 40
			}
		}
	},
	props: {
		MuiButton: {
			disableElevation: true
		},
		MuiButtonGroup: {
			disableElevation: true
		}
	}
};
