import React, { useContext } from "react";
import clsx from "clsx";
import loadable from "@loadable/component";
import PageContext from "@PageContext";

import styles from "./Desktopmenu.styles";

const ReactLink = loadable(() => import("@micado-digital/react-link/ReactLink"));

const Desktopmenu = () => {
	const css = styles();
	const [pageData] = useContext(PageContext);
	const { menuGroups } = pageData || {};
	const mainmenu = menuGroups?.find(group => group.title === "Hauptmenü")?.items;

	return (
		<div className={clsx(css.desktopmenu, "mco-view-component-header-desktopmenu")}>
			<div className={css.wrapper}>
				{mainmenu?.map(item => {
					const { id, target, title, link, selected } = item;

					return (
						<div
							className={clsx(css.item, "mco-view-component-header-desktopmenu__item")}
							key={id}
						>
							<ReactLink
								className={clsx(css.link, "mco-view-component-header-desktopmenu__link", {
									[css.itemSelected]: selected,
									"mco-view-component-header-desktopmenu__link--selected": selected
								})}
								dangerouslySetInnerHTML={{ __html: title }}
								target={target}
								to={link}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default Desktopmenu;
