import { useContext, useEffect, useState } from "react";
import PageContext from "@PageContext";

const usePartnerSalesChannel = () => {
	const [pageData] = useContext(PageContext);
	const { layoutVariant } = pageData;
	const [isPartnerMode, setIsPartnerMode] = useState();

	useEffect(() => {
		const tmpIsPartnerMode = sessionStorage.getItem("mco-ski-shop-partner") === "true";

		if (tmpIsPartnerMode) {
			document.body.classList.add("partner");
		}

		setIsPartnerMode(tmpIsPartnerMode);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (layoutVariant === "partner") {
			document.body.classList.add("partner");
			sessionStorage.setItem("mco-ski-shop-partner", true);
			setIsPartnerMode(true);
		}
	}, [layoutVariant]);

	return { isPartnerMode };
};

export default usePartnerSalesChannel;
