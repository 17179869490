import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	elements: {
		display: "flex",
		flexDirection: "column",
		gap: theme.spacing(10),
		scrollMarginTop: 75,
		position: "relative",
		zIndex: 1,
		"& > *:first-child:not(.mco-collapsing)": {
			marginTop: theme.spacing(7)
		},
		"& > *:last-child:not(.mco-collapsing)": {
			marginBottom: theme.spacing(7)
		},
		[theme.breakpoints.up("md")]: {
			"& > *:first-child:not(.mco-collapsing)": {
				marginTop: theme.spacing(10.5)
			},
			"& > *:last-child:not(.mco-collapsing)": {
				marginBottom: theme.spacing(10.5)
			}
		}
	}
}));

export default styles;
