import makeStyles from "@material-ui/styles/makeStyles";

const styles = ({ season }) => {
	return makeStyles(theme => ({
		header: {
			background:
				season === "winter"
					? theme.palette.background.winter
					: theme.palette.background.summer,
			display: "flex",
			flexDirection: "column",
			height: 140,
			position: "sticky",
			top: 0,
			zIndex: 800,
			[theme.breakpoints.up("md")]: {
				height: 90
			}
		},
		headerScroll: {
			boxShadow: "0px 4px 5px 0px rgb(10 80 130 / 8%)"
		},
		container: {
			alignItems: "center",
			display: "grid",
			gap: theme.spacing(2),
			height: "100%",
			[theme.breakpoints.down("xs")]: {
				"& .mco-view-component-header-icons": {
					gridColumn: 2,
					gridRow: 1
				},
				"& .mco-view-component-header-desktopmenu": {
					gridColumn: "1 / 3"
				}
			},
			[theme.breakpoints.up("sm")]: {
				gridTemplateColumns: "160px auto",
				padding: 0
			},
			[theme.breakpoints.up("md")]: {
				gap: theme.spacing(4),
				gridTemplateColumns: "200px auto 200px"
			},
			[theme.breakpoints.up("lg")]: {
				gridTemplateColumns: "256px auto 256px"
			}
		}
	}))();
};

export default styles;
