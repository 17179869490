import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	desktopmenu: {
		alignItems: "center",
		display: "flex",
		marginLeft: theme.spacing(3),
		overflow: "hidden",
		minHeight: 48,
		"-webkit-overflow-scrolling": "touch",
		[theme.breakpoints.up("md")]: {
			marginLeft: 0,
			minHeight: "none"
		}
	},
	wrapper: {
		overflowX: "scroll",
		flex: "1 1 auto",
		display: "inline-block",
		position: "relative",
		whiteSpace: "nowrap",
		[theme.breakpoints.up("md")]: {
			overflowX: "unset",
			flex: "unset",
			display: "flex",
			gap: theme.spacing(4)
		},
		[theme.breakpoints.up("lg")]: {
			gap: theme.spacing(6)
		}
	},
	item: {
		display: "inline-block",
		height: 34,
		marginRight: theme.spacing(3),
		[theme.breakpoints.up("md")]: {
			display: "flex",
			height: "auto",
			marginRight: 0
		}
	},
	link: {
		color: theme.palette.secondary.main,
		display: "block",
		fontSize: 16,
		fontWeight: theme.typography.fontWeightBold,
		position: "relative",
		textTransform: "uppercase",
		transition: "color 220ms ease-out",
		"&:after": {
			background: theme.palette.secondary.main,
			content: "''",
			display: "block",
			height: 4,
			position: "absolute",
			transition: "width 120ms ease-out",
			width: 0
		},
		"&:hover": {
			color: theme.palette.primary.main,
			"&:after": {
				background: theme.palette.primary.main,
				width: "100%"
			}
		},
		[theme.breakpoints.up("sm")]: {
			fontSize: 18
		}
	},
	itemSelected: {
		color: theme.palette.primary.main,
		"&:after": {
			background: theme.palette.primary.main,
			width: "100%"
		}
	}
}));

export default styles;
